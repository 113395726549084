import React, { useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/joy/Stack';
import Box from '@mui/system/Box';
import { Button, MenuItem, Select } from '@material-ui/core';

const sx = {
  fontSize: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
};

type Props = {
  totalMg: number;
  totalPercent: number;
}
export const CalculatorPercent = (props: Props) => {
  return (
    <Box sx={sx}>
      Total equivalent plain lignocaine dose: <Box component="span">{props.totalMg}</Box> mg = {props.totalPercent}% max
    </Box>
  )
}
