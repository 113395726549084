import React, { useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/joy/Stack';
import Box from '@mui/system/Box';
import { Button, MenuItem, Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { Data } from './Calculator';
import { CalculatorIndex } from './CalculatorIndex';
import Input from '@mui/material/Input';

const stackSx = {
  marginBottom: '0.75rem',
}
const vol2Mg = (vol, conc) => {
  return vol * conc * 10;
}
const toLigAmount = ({ drug, amount }: { drug: string, amount: number}): number => {
  if (drug === 'Lignocaine') {
    return amount;
  }
  if (drug === 'Ropivacaine') {
    return amount / 3 * 4.5;
  }
  if (drug === 'Bupivacaine') {
    return amount / 2.5 * 4.5;
  }
  return 0;
}

const getLigMg = ({ drug, units, amount, conc }) => {
  const ligAmount = toLigAmount({ drug, amount });
  if (units === 'mL') {
    return vol2Mg(ligAmount, conc);
  }
  if (units === 'sprays') {
    const vol = ligAmount / 10; // 1 spray = 100 microL
    const mg = vol2Mg(vol, conc);
    return mg;
  }
  return 0;
}


type CalculatorRow = {
  weight: number;
  index: number;
  data: Data;
  handleChange: (data: Data, index: number) => void;
  handleRemove: (index: number) => void;
}
export const CalculatorRow = (props: CalculatorRow) => {
  const [drug, setDrug] = useState(props.data.drug);
  const [conc, setConc] = useState(props.data.conc);
  const [units, setUnits] = useState(props.data.units);
  const [mg, setMg] = useState(props.data.mg);
  const [mgLig, setMgLig] = useState(props.data.mgLig);
  const [amount, setAmount] = useState(props.data.amount);


  const handleSelectDrugChange = (event) => {
    const val = event.target.value;
    setDrug(val);
    const mgLig = getLigMg({ drug: val, units, amount, conc })
    const updated: Data = { drug: val, conc, units, mgLig, mg, amount }
    props.handleChange(updated, props.index);
  }
  const handleSelectUnitsChange = (event) => {
    const val = event.target.value;
    setUnits(val);
    const mgLig = getLigMg({ drug, units: val, amount, conc })
    const updated: Data = { drug, conc, units: val, mgLig, mg, amount }
    props.handleChange(updated, props.index);
  }
  const handleInputConcChange = e => {
    const val = e.target.value;
    setConc(val);
    const mgLig = getLigMg({ drug, units, amount, conc: val })
    const updated: Data = { drug, conc: val, units, mgLig, mg, amount }
    props.handleChange(updated, props.index);
  }

  const handleInputAmountChange = (e) => {
    const val = e.target.value;
    setAmount(val);
    const mgLig = getLigMg({ drug, units, amount: val, conc })!;
    setMgLig(mgLig);
    const updated: Data = { drug, conc, units, mgLig, mg, amount: val }
    props.handleChange(updated, props.index);

  }
  const handleRemove = () => {
    props.handleRemove(props.index);
  }


  return (
    <Box>
      {/* {console.log('props.data:', props.data)} */}
      <Stack direction="row" spacing={2} alignItems="center" sx={stackSx}>
        <CalculatorIndex index={props.index} />
        <Box>
          {/* <InputLabel id={`select-label-${props.index}`}>Drug</InputLabel> */}
          <Select
            labelId={`select-label-${props.index}`}
            id={`select-${props.index}`}
            value={props.data.drug}
            // label="Drug"
            autoWidth
            onChange={handleSelectDrugChange}
          >
            <MenuItem value={'Lignocaine'}>Lignocaine</MenuItem>
            <MenuItem value={'Ropivacaine'}>Ropivacaine</MenuItem>
            <MenuItem value={'Bupivacaine'}>Bupivacaine</MenuItem>
            {/* <MenuItem value={'Cophenylcaine'}>Cophenylcaine</MenuItem> */}
          </Select>
        </Box>
        <Box>
          <Input
            value={props.data.conc}
            type="number"
            inputProps={{ min: "0", max: "10", step: "1" }}
            onChange={handleInputConcChange}
          />
          %
        </Box>

        <Box>=</Box>

        <Box>
          <Input
            value={props.data.amount}
            type="number"
            inputProps={{ min: "0", max: "1000", step: "1" }}
            onChange={handleInputAmountChange}
          />
        </Box>

        <Box>
          {/* <InputLabel id={`select-label-${props.index}`}>Drug</InputLabel> */}
          <Select
            labelId={`select-delivery-label${props.index}`}
            id={`select-delivery${props.index}`}
            value={props.data.units}
            // label="Drug"
            autoWidth
            onChange={handleSelectUnitsChange}
          >
            <MenuItem value={'mL'}>mL</MenuItem>
            <MenuItem value={'sprays'}>spray(s)</MenuItem>
          </Select>
        </Box>

        <Box sx={{}}>
          <Button variant="outlined" onClick={handleRemove}>Remove</Button>
        </Box>

      </Stack>
    </Box>
  )
}
