import React, { useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/joy/Stack';
import Box from '@mui/system/Box';

const sx = {
  fontSize: '1.5rem'
};

type Props = {
  index: number;
}
export const CalculatorIndex = (props: Props) => {
  return (
    <Box sx={sx}>
      {props.index + 1}
    </Box>
  )
}
