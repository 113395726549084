import React, { useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/joy/Stack';
import Box from '@mui/system/Box';
import { Button, MenuItem, Select } from '@material-ui/core';

const sx = {
  fontSize: '1.5rem',
  marginTop: '1rem',
};

type Props = {
  handleAdd: () => void;
}
export const CalculatorAdd = (props: Props) => {
  return (
    <Box sx={sx}>
      <Button variant="contained" onClick={props.handleAdd}>Add drug</Button>
    </Box>
  )
}
