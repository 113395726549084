import _ from 'lodash-es';

import { BASE } from "./local-data";

export const calculateMaxVol = (conc: number, max: number, weight: number) => {
  const maxMg = max * weight;
  const maxVol = maxMg / (conc * 10);
  const roundedMaxVol = _.round(maxVol);
  return roundedMaxVol;
}

const generateRow = (max: number, weight: number, conc: number) => {
  const maxMg = max * weight;
  const roundedMaxVol = calculateMaxVol(conc, max, weight);

  const result = {
    left: `Max: ${max} mg/kg = ${maxMg} mg`,
    right: `${roundedMaxVol} mL`,
  }
  return result;
}


export const maxDosesForOneLocalConc = (name: string, conc: number, weight: number, max: number[]) => {
  const result = {
    display: `${conc}% ${name} = ${conc*10} mg/mL`,
    background: BASE.WHITE,
    text: 'black',
    values: max.map(m => generateRow(m, weight, conc)),
  }
  return result;
}
