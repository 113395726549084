import React, { useState } from 'react';
import _ from 'lodash-es';
import styled from '@emotion/styled';
import Box from '@mui/system/Box';
import { CalculatorRow } from './CalculatorRow';
import { CalculatorAdd } from './CalculatorAdd';
import { CalculatorPercent } from './CalculatorPercent';

export const calculateMg = (conc: number, vol: number) => {
  const mg = vol * (conc * 10);
  const rounded = _.round(mg);
  return mg;
}
const LIG_MAX = 4.5;

export const calculatePercentMax = (mgLig, weight) => {
  const max = weight * LIG_MAX;
  const percent = mgLig / max * 100;
  const rounded = _.round(percent);
  return rounded;
}


const containerSx = {
  marginBottom: '3rem',
}

export type Data = {
  drug: string;
  conc: number;
  amount: number;
  units: string;
  mg: number;
  mgLig: number;
}
const Drugs = [
  { name: 'Lignocaine'}
];
const Initial = { drug: 'Lignocaine', conc: 1, amount: 10, units: 'mL', mg: 100, mgLig: 100 };

const initial = {
}

type Props = {
  weight: number;
}
export const Calculator = (props: Props) => {
  const [allData, setAllData] = useState([Initial])
  const [totalMgLig, setTotalMgLig] = useState(Initial.mgLig);
  const [totalPercent, setTotalPercent] = useState(calculatePercentMax(Initial.mgLig, props.weight));

  const handleAdd = () => {
    const added = [...allData, Initial];
    setAllData(added);
    const total = added.reduce((acc: number, curr: Data) => {
      const sum = acc + curr.mgLig;
      return sum;
    }, 0);
    const percent = calculatePercentMax(total, props.weight);
    setTotalPercent(percent);
  }

  const handleRemove = (index: number) => {
    console.log('index:', index);
    const updated = allData.filter((val, i) => {
      if (i === index) { console.log('Removing:', val)}
      return i !== index;
    })
    console.log('updated:', updated);
    setAllData(updated);
    const total = updated.reduce((acc: number, curr: Data) => {
      const sum = acc + curr.mgLig;
      return sum;
    }, 0);
    const percent = calculatePercentMax(total, props.weight);
    setTotalPercent(percent);
  }

  const handleChange = (newData, index) => {
    console.log('newData:', newData);
    const updated = allData;
    updated[index] = newData;
    setAllData(updated);
    const total = allData.reduce((acc: number, curr: Data) => {
      const sum = acc + curr.mgLig;
      return sum;
    }, 0);
    setTotalMgLig(total);
    const percent = calculatePercentMax(total, props.weight);
    setTotalPercent(percent);
  }

  return (
    <Box sx={containerSx}>
      <h2>Calculator</h2>
      {/* {console.log('allData:', allData)} */}

      <CalculatorPercent totalMg={totalMgLig} totalPercent={totalPercent} />

      {allData.map((d, i) => {
        return (
          <CalculatorRow weight={props.weight} index={i} data={d} key={d.drug+i} handleChange={handleChange} handleRemove={handleRemove} />
        )
      })}
      <CalculatorAdd handleAdd={handleAdd} />
    </Box>
  )
}

