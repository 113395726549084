import _ from 'lodash-es';
import { Cefazolin, Dantrolene, Dexamethasone, Glucose_5, Intralipid, Rocuronium, Ondansetron, Suxamethonium, Vecuronium, Propofol, Ketamine, Fentanyl, Oxycodone, Paracetamol, Ibuprofen, Parecoxib, Midazolam_Premed, Ketamine_Premed, Clonidine_Premed, Clonidine_Adjunct, Magnesium, Dexmedetomidine_Adjunct, Metaraminol, Sugammadex, Neostigmine, Glycopyrrolate_Reversal, Atropine, IVF_Bolus, IVF_Maintenance, Blood_PRBC, Blood_FFP, Blood_Cryo, Blood_Platelets, TXA, Calcium_Chloride, Thiopentone_Induction, Amiodarone, Adenosine, Mannitol, Hypertonic_Saline, Thiopentone_ICP, LA_Bupivacaine_025_Ad, LA_Lignocaine_1, Electricity_Cardioversion, Electricity_Defib, Adrenaline, Bicarb, Remifentanil, Naloxone, Insulin, Glucose_10, Glucose_50 } from './drugs-list';
import * as Magic from './drugs-list';
import { maxDosesForOneLocalConc } from './local-list';

export const BASE = {
  RED: '#F4303D',
  RED_LIGHT: '#ff9194',
  ORANGE: '#F7941D',
  YELLOW: '#FEDE59',
  GREEN: '#97C259',
  BLUE: '#38B6FF',
  PURPLE: '#D0B7D2',
  PINK: '#FAD5E5',
  GRAY: '#808080',
  GRAY_DARK: '#4D4D4D',
  WHITE: '#FFFFFF',
};
export const COLORS = {
  OPIOIDS: BASE.BLUE,
  NMBA: BASE.RED,
  ANTI_CHOLINERGIC: BASE.GREEN,
  ANTI_EMETIC: '#edc281',
  INDUCTION: BASE.YELLOW,
  VASOPRESSOR: BASE.PINK,
  VASODILATOR: '',
  DEFAULT_BACKGROUND: '#444',
  DEFAULT_TEXT: 'black',
  NEOSTIGMINE: `repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  )`,
};
const round = (num: number, precision = 0) => {
  // 0 = round number, 1 = 0.1, 2 = 0.01
  // https://betterprogramming.pub/the-never-ending-story-of-rounding-numbers-7071cdfc1c7c
  return _.round(num, precision);
}


// --------------------------------------------------------------------------------------------------
const valuesLignocaine = (weight: number) => ({
  section: 'Lignocaine',
  values: [
    maxDosesForOneLocalConc('Lignocaine', 1, weight, [3, 4.5, 7, 9]),
    maxDosesForOneLocalConc('Lignocaine', 2, weight, [3, 4.5, 7, 9]),
  ]
})
const valuesRopivacaine = (weight: number) => ({
  section: 'Ropivacaine',
  values: [
    maxDosesForOneLocalConc('Ropivacaine', 1, weight, [3]),
    maxDosesForOneLocalConc('Ropivacaine', 0.75, weight, [3]),
    maxDosesForOneLocalConc('Ropivacaine', 0.5, weight, [3]),
    maxDosesForOneLocalConc('Ropivacaine', 0.2, weight, [3]),
  ]
})
const valuesBupivacaine = (weight: number) => ({
  section: 'Bupivacaine',
  values: [
    maxDosesForOneLocalConc('Bupivacaine', 0.5, weight, [2.5]),
    maxDosesForOneLocalConc('Bupivacaine', 0.25, weight, [2.5]),
  ]
})



export const getColumnA = (age: number, weight: number, ageUnits: string) => [
  valuesLignocaine(weight),
  valuesRopivacaine(weight),
]

export const getColumnB = (age: number, weight: number, ageUnits: string) => [
  valuesBupivacaine(weight),
]


export const getColumnAll = (age: number, weight: number, ageUnits: string) => [
  valuesLignocaine(age),
  valuesRopivacaine(weight),
  valuesBupivacaine(weight),
]


const keys: string[] = _.keys(Magic);

type MagicType = any;

export const getAll = (age: number, weight: number, ageUnits: string) => keys.map(k => (Magic as MagicType)[k](age, weight, ageUnits));


/*
https://wikem.org/wiki/Intubation_(peds)


*/
