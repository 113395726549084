import React, { useState, useEffect, useRef } from 'react';
import { Button, MenuItem, Select } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from '@emotion/styled';
import Link from '@mui/material/Link';


import { getTheme } from '../utility/theme';
import Layout from '../components/Layout';
import { COLOR, MAX_TABLET_WIDTH, PRIMARY } from '../constants';
import { COLORS, getAll, getColumnA, getColumnAll, getColumnB } from '../utility/local-data'
import SEO from '../components/seo';
import { isEmptyHeader } from '../components/Header';
import { Calculator } from '../components/Calculator/Calculator';
import { calculateMaxVol } from '../utility/local-list';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });


const Spacer = styled.div`
  height: 2rem;
`;

const userTheme = getTheme();
const theme = muiTheme(userTheme);


// const calculateETT = (weight)

const SectionTitle = styled.div`
  text-transform: uppercase;
  border-bottom: 1px solid white;
`;

type SectionRowProps = {
  color?: string;
  index?: number;
  background?: string;
  text?: string;
  backgroundImage?: string;
  backgroundSize?: string;
}
const PanelItem = styled.div<SectionRowProps>`


`;

const PanelItemHeading = styled.div`
  ::before {
    content: '• ';
    margin-left: -0.5rem;
  }
  margin-top: 0.5rem;
`;
const MainLeft = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;
const MainRight = styled.div``;
const PanelBody = styled.div``;
const PanelItemRows = styled.div``;

const PanelItemRow = styled.div<SectionRowProps>`
  background-color: ${props => props.background || COLORS.DEFAULT_BACKGROUND};
  color: ${props => props.text || COLORS.DEFAULT_TEXT};
  opacity: ${props => {
    const opacity = 1 - ((props!.index! + 1) / 7);
    return opacity;
  }};
  background-image: ${props => props.backgroundImage || null};
  background-size: ${props => props.backgroundSize || null};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1px -4px;
  padding: 1px 4px;
  cursor: pointer;
`;
const SubRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const RowDose = styled.div`
  display: flex;
  font-size: 0.8rem;
  font-style: italic;
`;

const AGE_UNITS = {
  years: 'years',
  months: 'months',
  days: 'days',
};


type SummaryProps = {
  emptyHeader: boolean;
}
const Summary = styled.div<SummaryProps>`
  margin-top: ${props => props.emptyHeader ? `-4.5rem` : `-1.5rem`}; // wtf
  margin-left: -1rem;
  position: fixed;
  background-color: #121212;
  border-bottom: 1px solid ${PRIMARY};
  width: 100%;
  padding: 1rem;
  padding-right: 3rem;
  z-index: 2; // what is going on here
  display: flex;
  flex-direction: row;
  justify-content: flex-start; // can't get width: 100% inherit working for this fixed element
  height: 3rem;

  + h1 {
    margin-top: ${props => props.emptyHeader ? `0rem` : `3rem`}; // wtf
    margin-bottom: 2rem;
    display: ${props => props.emptyHeader ? `none` : `block`}; // wtf
  }

  div {
    margin-right: 2rem;
  }
  div:last-of-type {
    margin-right: 0;
  }

  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    margin-top: ${props => {
      return props.emptyHeader ? `-4.5rem` : `-0.5rem`; // wtf
    }}

    div {
      margin-right: 3rem;
    }
  }
`;

const SummaryItem = styled.div`
  display: flex;
`;
const CssContainer = styled.div`
  .columns-single {
    display: none;
    @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
      display: block;
    }
  }
  .columns-double {
    display: flex;
    @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
      display: none;
    }
  }
`
const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    flex-direction: column;
  }
`;


const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  :first-of-type {
    margin-right: 1rem;
  }

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    flex-basis: 100%;

    :first-of-type {
      margin-right: 0;
    }
  }
`;

type InputsProps = {
  emptyHeader: boolean;
}
const Inputs = styled.div<InputsProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 0rem; // wtf
`;
const Left = styled.div`
  display: flex;

  button,
  .MuiInputBase-root  {
    margin-right: 1rem;
  }
`;

const Panel = styled.div`
  summary {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid white;
    margin-bottom: 0.5rem;
  }

  & > details {
    & {
      /* background: blue; */
    }
    /* background: pink; */
  }





  margin-bottom: 1rem;
  background: #222;
  padding: 1rem;

`;

const Div = styled.div``;


const generateColumns = (data, isActive, handleToggle, isStart) => {

  const detailsAttr = (index, section) => {
    if (isStart && section.section === 'Essentials') return true;
    return false;
  }
  return (data.map((section, i) => {
    return (
    <Panel key={section.section}>
      <details open={detailsAttr(i, section)}>
        <summary>
          <span className="wrapper">
            <span className="heading">{section.section}</span>
            <span className="pill">{i + 1} / {data.length}</span>
          </span>
        </summary>
        {/* <SectionTitle>{section.section}</SectionTitle> */}
          <PanelBody>
          {section.values.map((s, i) => {
            return (
            <PanelItem className={isActive ? 'active' : ''} onClick={handleToggle} key={s.display + i}>
              <PanelItemHeading>{s.display} </PanelItemHeading>
              <PanelItemRows>
                {s.values.map((v, i) => {
                  const key = v.left + i;
                  return (
                  <PanelItemRow background={s.background} text={s.text} index={i} key={key} backgroundSize={s.backgroundSize} backgroundImage={s.backgroundImage}>
                      <SubRow>
                        <MainLeft>{v.left}</MainLeft>
                        <MainRight>{v.right}</MainRight>
                      </SubRow>
                    </PanelItemRow>
                )})}

              </PanelItemRows>
            </PanelItem>
          )})}
        </PanelBody>
      </details>
    </Panel>

  )}))
}

const ToggleContainer = styled.div`
  display: flex;
  #id {
    position: right;
  }
`;

const getMaxVol = (maxMgKg, weight, conc) => {
  const maxMgWeight = maxMgKg * weight;
  const maxVol = maxMgWeight / conc * 10
  return maxVol;
}

const Volume = styled.div`
  width: 20rem;
`
type CWProps = {
  width?: string;
  background?: string;
}
const ContainerWidth = styled.div`
  width: ${(props: CWProps) => props.width ? props.width : 'auto'};
  background-color: ${(props: CWProps) => props.background ? props.background : 'auto'};
  margin-left: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  justify-content: center;
`


export default function Local() {
  const defaultWeight = 70;
  const [weight, setWeight] = useState(defaultWeight);
  const [age, setAge] = useState(1);
  const [ageUnits, setAgeUnits] = useState(AGE_UNITS.years);

  const defaultConc = 1;
  const [conc, setConc] = useState(defaultConc);

  const [drugName, setDrugName] = useState('Lignocaine')

  const defaultMaxMgKg = 1;
  const [maxMgKg, setMaxMgKg] = useState(defaultMaxMgKg);

  const defaultMaxVol = calculateMaxVol(defaultConc, defaultMaxMgKg, defaultWeight);
  const [maxVol, setMaxVol] = useState(defaultMaxVol);


  const [isActive, setActive] = useState(false);
  const [isExpanded, setExpanded] = useState(true);
  const [order, setOrder] = useState('group');
  const [showHelp, setShowHelp] = useState(false);
  const [isStart, setIsStart] = useState(true);

  const handleWeight = (event) => {
    const val = event.target.value;
    setWeight(val);
  };

  const handleDrugName = (event) => {
    const val = event.target.value;
    setDrugName(val);
  }

  const handleConc = (event) => {
    const val = event.target.value;
    setConc(val);
    const maxVol = calculateMaxVol(val, maxMgKg, weight);
    setMaxVol(maxVol);
  }
  const handleMax = (event) => {
    const val = event.target.value;
    setMaxMgKg(val);
    const maxVol = calculateMaxVol(conc, val, weight);
    setMaxVol(maxVol);
  }

  const handleToggle = () => setActive(!isActive);

  const handleHelp = () => setShowHelp(!showHelp);

  const detailsExpand = (details) => {
    for (let i = 0; i < details.length; i += 1) {
      details[i].setAttribute('open', '');
      details[i].removeAttribute('closed');
    }
  }
  const detailsCollapse = (details) => {
    for (let i = 0; i < details.length; i += 1) {
      details[i].setAttribute('closed', '');
      details[i].removeAttribute('open');
    }
  }

  const handleTextToggle = () => {
    const details = document.getElementsByTagName('details');
    const toggle = document.getElementById('toggle')!;
    const current = toggle.className;
    if (current === 'expanded') {
      detailsCollapse(details);
      toggle.className = 'collapsed';
    } else {
      toggle.className = 'expanded';
      detailsExpand(details);
    }
  }


  useEffect(() => {
    function onKeyPress(e) {
      const details = document.getElementsByTagName('details');
      if (e.key === 'e') {
        if (isExpanded) {
          detailsCollapse(details);
          setExpanded(false);
        } else {
          detailsExpand(details);
          setExpanded(true);
        }
      }
    }
    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    }
  });

  const columnA_grouped = getColumnA(age, weight, ageUnits);
  const columnB_grouped = getColumnB(age, weight, ageUnits);
  const all = getAll(age, weight, ageUnits);


  const columnAll = getColumnAll(age, weight, ageUnits);

  const columnA_AZ = [{
    section: '',
    values: all.slice(0, all.length / 2),
  }];
  const columnB_AZ = [{
    section: '',
    values: all.slice(all.length / 2),
  }];

  const columnA = order === 'group' ? columnA_grouped : columnA_AZ;
  const columnB = order === 'group' ? columnB_grouped : columnB_AZ;




  const emptyHeader = isEmptyHeader();

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Local Anaesthetic Doses" />
        <Summary emptyHeader={emptyHeader} className="summary">
          <Div>
            Weight: {weight} kg
          </Div>

          <ToggleContainer>
            <div id="toggle" className="expanded" onClick={handleTextToggle}></div>
          </ToggleContainer>

          {/* <Div>
            <Help>?</Help>
            <ToolTip>
              One
              Two
              Three
            </ToolTip>
          </Div> */}
          {/* <ToggleContainer>
            <div>A-Z</div>
          </ToggleContainer> */}
        </Summary>

        <h1>Local Anaesthetic Doses</h1>


        <Inputs emptyHeader={emptyHeader} className="inputs">
          <Left>

            <FormControl variant="outlined">
              <OutlinedInput id="input-weight" value={weight} type="number" onChange={handleWeight} inputProps={{ min: "0", step: "1", max: "1000", width: "5rem", pattern: '/\d*/' }} label="weight" />
              {/* the "label" attribute on the OutlinedInput is for the width spacing for the <legend /> that creates the gap */}
              <InputLabel htmlFor="input-weight" variant="outlined" shrink>weight</InputLabel>
            </FormControl>

          </Left>

        </Inputs>

        <Spacer />

        <Calculator weight={weight} />

        <h2>Calculator</h2>


        <Left>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Drug</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={drugName}
              label="Drug"
              autoWidth
              onChange={handleDrugName}
            >
              <MenuItem value={'Lignocaine'}>Lignocaine</MenuItem>
              <MenuItem value={'Ropivacaine'}>Ropivacaine</MenuItem>
              <MenuItem value={'Bupivacaine'}>Bupivacaine</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>

          <ContainerWidth width="20rem">
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput id="input-conc" value={conc} type="number" onChange={handleConc} inputProps={{ min: "0", step: "1", max: "10", width: "20rem", pattern: '/\d*/' }} label="weight" />
              {/* the "label" attribute on the OutlinedInput is for the width spacing for the <legend /> that creates the gap */}
              <InputLabel htmlFor="input-conc" variant="outlined" shrink>conc %</InputLabel>
            </FormControl>
          </ContainerWidth>

          <ContainerWidth width='20rem'>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput id="input-max" value={maxMgKg} type="number" onChange={handleMax} inputProps={{ min: "0", step: "1", max: "10", width: "15rem", pattern: '/\d*/' }}
              fullWidth
              label="weight" />
              {/* the "label" attribute on the OutlinedInput is for the width spacing for the <legend /> that creates the gap */}
              <InputLabel htmlFor="input-max" variant="outlined" shrink>Max (mg/kg)</InputLabel>
            </FormControl>
          </ContainerWidth>

          <ContainerWidth width='20rem' background="#444">
            <div>
            Maximum volume:
            </div>
            <div>
            {maxVol} mL
            </div>
          </ContainerWidth>
        </Left>

        <Spacer />

        <h2>Common Formulations</h2>

        <CssContainer className="css-container">
          <DataContainer className="data-container columns-double">
            <DataColumn>
              {generateColumns(columnA, isActive, handleToggle, isStart)}
            </DataColumn>
            <DataColumn>
              {generateColumns(columnB, isActive, handleToggle, isStart)}
            </DataColumn>
          </DataContainer>
        </CssContainer>

        <CssContainer className="css-container">
          <DataContainer className="data-container columns-single">
            <DataColumn>
              {generateColumns(columnAll, isActive, handleToggle, isStart)}
            </DataColumn>
          </DataContainer>
        </CssContainer>

        <Spacer />

        <h2>References</h2>
        <p>Maximums used: Lignocaine 4.5 mg/kg, Ropivacaine 3 mg/kg, Bupivacaine 2.5 mg/kg</p>
        <p><Link target="_blank" rel="noreferrer" href="https://www.rch.org.au/clinicalguide/guideline_index/Local_anaesthetic_poisoning/">https://www.rch.org.au/clinicalguide/guideline_index/Local_anaesthetic_poisoning/</Link></p>
      </Layout>
    </ThemeProvider>
  );
}
